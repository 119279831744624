/** One of Dan and Regina's house plants */
export interface IHousePlant {

  /** A unique ID for this individual house plant */
  id: string;

  /** The name Regina and Dan generally use when referring to this house plant */
  name?: string;

  //todo: add more optional properties, like:
  // datePurchased
  // datePlanted
  // whereAquiredFrom
  // startType (propogated, seed, button, bought as older)
  // genus name
  // description
  // notes
  // soil type
  // light preferences
  // still with us? aka alive
  // etc....

}

/**
 * Given an array of house plant ids, returns a string containing the names of those plants (comma separated).
 * If a plant does not have a name, it will not be included in the output.
 * If a plant in the input list is not found in the AllHousePlants array, it will not be included in the output.
 * @param housePlantIds The list of house plant ids to get the names for.
 * @return A string containing the names of the house plants, or an empty string if no plants were found.
 */
export function getHousePlantNames(housePlantIds: string[] | undefined): string {
  if(housePlantIds === undefined) return '';
  let names: string = "";
  for(let id of housePlantIds) {
    const plant: IHousePlant | undefined = AllHousePlants.find(p => p.id === id);
    if(plant && plant.name) {
      if(names.length > 0){
        names += ", ";
      }
      names += plant.name;
    }
  }
  return names;
} 

/** A list of all the house plants Regina and Dan have */
export const AllHousePlants: IHousePlant[] = [
  {
    id: "IkUrwZuz9EZuA8h5GwLh",
    name: "Dan's Home Depot Cactus"
  },
  {
    id: "aJkDXbDYuEQde8jtsshH",
    name: "Other Plant"
  },
  {
    id: "XKQZpV9A9qJiG8t6fN2L",
    name: "Saguaro 1"
  },
  {
    id: "uZf4Q9V9A9qJiG8t6fN3",
    name: "Saguaro 2"
  },
  {
    id: "lZf4Q9V9A9qJiG8t6fN4",
    name: "Aerogrow"
  },
  {
    id: "mZf4Q9V9A9qJiG8t6fN5",
    name: "Watermelon Peperomia"
  },
  {
    id: "nZf4Q9V9A9qJiG8t6fN6",
    name: "Ikea Pothos(?)"
  },
  {
    id: "oZf4Q9V9A9qJiG8t6fN7",
    name: "White Wizard"
  },
  {
    id: "pZf4Q9V9A9qJiG8t6fN8",
    name: "Prayer Plant"
  },
  {
    id: "qZf4Q9V9A9qJiG8t6fN9",
    name: "Jade(?)"
  },
  {
    id: "rZf4Q9V9A9qJiG8t6fN0",
    name: "Orange Poinsettia"
  },
  {
    id: "sZf4Q9V9A9qJiG8t6fN1",
    name: "Monstera Deliciosa(?)"
  },
  {
    id: "tZf4Q9V9A9qJiG8t6fN2",
    name: "Holiday Cacuts"
  },
  {
    id: "uZf4Q9V9A9qJiG8t6fN3",
    name: "Silver Satin(?) Pothos"
  },
  {
    id: "vZf4Q9V9A9qJiG8t6fN4",
    name: "Mother of Thousands"
  },
  {
    id: "wZf4Q9V9A9qJiG8t6fN5",
    name: "String of Pearls"
  },
  {
    id: "xZf4Q9V9A9qJiG8t6fN6",
    name: "String of Turtles"
  },
  {
    id: "yZf4Q9V9A9qJiG8t6fN7",
    name: "Aloe"
  },
  {
    id: "zZf4Q9V9A9qJiG8t6fN8",
    name: "Ghost Plant(?)"
  },
  {
    id: "A0Zf4Q9V9A9qJiG8t6fN9",
    name: "Bear's Paw"
  },
  {
    id: "B1Zf4Q9V9A9qJiG8t6fNA",
    name: "Peen Cactus(?)"
  },
  {
    id: "C2Zf4Q9V9A9qJiG8t6fNB",
    name: "Tiny Weeny Beany?"
  },
  {
    id: "D3Zf4Q9V9A9qJiG8t6fNC",
    name: "Tiny Cactus Stick?"
  },
  {
    id: "E4Zf4Q9V9A9qJiG8t6fND",
    name: "Haworthia(?)"
  },
  {
    id: "F5Zf4Q9V9A9qJiG8t6fNE",
    name: "Dark/Light Green Pothos(?)"
  },
  {
    id: "G6Zf4Q9V9A9qJiG8t6fNF",
    name: "Purple Tradescantia"
  },
  {
    id: "H7Zf4Q9V9A9qJiG8t6fNG",
    name: "Tall Succulent?"
  },
  {
    id: "I8Zf4Q9V9A9qJiG8t6fNH",
    name: "Two Stick Segmented Cactus(?)"
  },
  {
    id: "J9Zf4Q9V9A9qJiG8t6fNI",
    name: "Small Spiky Friend(?)"
  },
  {
    id: "K0Zf4Q9V9A9qJiG8t6fNJ",
    name: "Blue Chalk"
  },
  {
    id: "L1Zf4Q9V9A9qJiG8t6fNK",
    name: "Song of India"
  },
  {
    id: "M2Zf4Q9V9A9qJiG8t6fNL",
    name: "Shy Spotted Aloe(?)"
  },
  {
    id: "N3Zf4Q9V9A9qJiG8t6fNM",
    name: "Spiky Spotted Aloe(?)"
  },
  {
    id: "O4Zf4Q9V9A9qJiG8t6fNN",
    name: "Shedding Succulent(?)"
  },
  {
    id: "P5Zf4Q9V9A9qJiG8t6fNO",
    name: "Tall Wondering Succulent(?)"
  },
  {
    id: "Q6Zf4Q9V9A9qJiG8t6fNP",
    name: "Spider Plant(?)"
  },
  {
    id: "R7Zf4Q9V9A9qJiG8t6fNQ",
    name: "Hibiscus"
  },
  {
    id: "S8Zf4Q9V9A9qJiG8t6fNR",
    name: "Calamansi"
  },
  {
    id: "T9Zf4Q9V9A9qJiG8t6fNS",
    name: "Daytime Nighttime"
  },
  {
    id: "U0Zf4Q9V9A9qJiG8t6fNT",
    name: "Orchid 1"
  },
  {
    id: "V1Zf4Q9V9A9qJiG8t6fNU",
    name: "ZZ"
  },
  {
    id: "W2Zf4Q9V9A9qJiG8t6fNV",
    name: "Dan's Snake"
  },
  {
    id: "X3Zf4Q9V9A9qJiG8t6fNW",
    name: "Dense Cactus Thing(?)"
  },
  {
    id: "Y4Zf4Q9V9A9qJiG8t6fNX",
    name: "Rosemary Tree"
  },
];