<template>

  <SectionHeader
    icon="mdi-cart"
    title="Kat Shopping List"
  />

  <div class="flex flex-col gap-4 justify-center items-center">
    <ShoppingListItem
      v-for="item in shoppingListItems"
      :item="item"
      :key="item.name"
      @deleteItem="onDeleteItem"
      @sortItems="sortShoppingList"
      @updateItem="updateShoppingItem"
    />
    <v-btn
      class="w-64 italic"
      prepend-icon="mdi-plus"
      rounded
      variant="tonal"
    >
      <ShoppingItemFormModal @updatedShoppingItem="addOrUpdateShoppingItem" />
    </v-btn>
  </div>



</template>

<script lang="ts">
import ShoppingListItem from '@/components/ShoppingListItem.vue';
import SectionHeader from './SectionHeader.vue';
import { ShoppingItem } from '@/models/ShoppingItem';
import ShoppingItemFormModal from '@/components/ShoppingItemFormModal.vue';
import { addOrUpdateShoppingItem, deleteShoppingItem, getAllShoppingItems } from '@/services/DatabaseService';
import type { IResult } from '@/models/IResult';

export default {
  components: {
    ShoppingListItem,
    SectionHeader,
    ShoppingItemFormModal,
  },
  data() {
    return {
      /** A list of shopping items to display in this component */
      shoppingListItems: [] as ShoppingItem[],
      showShoppingItemForm: false,
    }
  },
  methods: {
    addOrUpdateShoppingItem: async function (newShoppingItemName: string): Promise<void> {
      const newShoppingItem: ShoppingItem = new ShoppingItem(
        Date.now(),
        newShoppingItemName,
        false
      );
      await addOrUpdateShoppingItem(newShoppingItem);
      this.getShoppingList();
    },
    getShoppingList: async function (): Promise<void> {
      const result: IResult<ShoppingItem[]> = await getAllShoppingItems();
      if (result.success) {
        this.shoppingListItems = result.value!;
      }
    },
    onDeleteItem: async function (idOfItemToDelete: string): Promise<void> {
      await deleteShoppingItem(idOfItemToDelete);
      this.getShoppingList();
    },
    updateShoppingItem: async function (idOfItemToDelete: string): Promise<void> {
      const shoppingItemToUpdate: ShoppingItem = this.shoppingListItems.find((item) => item.created.toString() === idOfItemToDelete)!;
      await addOrUpdateShoppingItem(shoppingItemToUpdate);
      this.getShoppingList();
    },
    sortShoppingList: function (): void {
      this.shoppingListItems.sort((a, b) => {
        if (a.got === b.got) {
          return a.created - b.created;
        } else if (a.got) {
          return 1;
        } else {
          return -1;
        }
      });
    },
  },
  mounted: async function (): Promise<void> {
    await this.getShoppingList();
  },
}
</script>