<template>
  <div class="flex flex-col mx-16">

    <!-- title -->
    <SectionHeader icon="mdi-sprout" title="Plants" />

    <!-- select a plant to view only its photos -->
    <div class="mb-4">
      <v-autocomplete
        chips
        clearable
        :items="AllHousePlants"
        item-title="name"
        item-value="id"
        label="Show photos of a specific plant"
        multiple
        v-model="selectedHousePlant2Ids"
      ></v-autocomplete>
    </div>

    <!-- show selected plant name if a plant is selected -->
    <div v-if="selectedHousePlant2Ids.length > 0" class="mb-4">
      <h2 class="text-xl font-bold">{{ selectedHousePlantName }}</h2>
    </div>

    <!-- plant photo gallery --> 
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-16">
      <div
        v-for="plantPhotoData in plantPhotoDatasToDisplay"
        :key="plantPhotoData.id"
        class="bg-gray-200 p-4 rounded-lg cursor-pointer transition duration-300 ease-in-out hover:bg-gray-300"
        @click="onPlantPhotoClicked(plantPhotoData.plantIds)"
      >
        <img
          :src="plantPhotoData.url"
          class="object-cover w-full h-64 mb-4"
        />
        <p
          v-if="selectedHousePlant2Ids.length === 0"
          class="font-bold"
        >
          {{ getHousePlantNames(plantPhotoData.plantIds) }}
        </p>
        <p class="text-xs text-gray-500">{{ new Date(plantPhotoData.date).toLocaleDateString() }}</p>
      </div>
    </div>

    <!-- upload photo inputs -->
    <div class="flex flex-col md:flex-row md:gap-8">

      <!-- select file inputs -->
      <v-file-input
        accept="image/*"
        chips
        clearable
        counter
        label="Upload Plant Photos"
        multiple
        prepend-icon="mdi-camera-plus"
        show-size
        v-model="selectedFiles"
      />

      <!-- Select House Plant Dropdown -->
      <v-autocomplete
      class=" min-w-48"
        clearable
        :items="AllHousePlants"
        item-title="name"
        item-value="id"
        label="Select Plant for All Photos"
        v-model="selectedHousePlantId"
      ></v-autocomplete>

    </div>

    <!-- list of files selected for uploading -->
    <v-expansion-panels>

      <v-expansion-panel
        v-for="plantPhotoData in plantPhotoDatasToUpload"
        :key="plantPhotoData.id"
      >
        <v-expansion-panel-title>
          <p class="text-lg font-bold">{{  plantPhotoData.fileName }}</p>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="flex gap-4">

            <!-- preview thumbnail of image being uploaded -->
            <img
              :src="plantPhotoData.url"
              class="w-48 h-48 object-cover"
            />

            <!-- meta data about plant photo being uploaded -->
            <div class="flex flex-col">

              <!-- date photo was taken -->
              <p><span class="font-bold">Date Taken:</span> {{ new Date(plantPhotoData.date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) }} {{ new Date(plantPhotoData.date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) }}</p>

              <!-- plant photo will be tagged as -->
              <p>
                <span class="font-bold mr-2">Plant:</span>
                <span
                  class="px-2 rounded-full"
                  :class="!plantPhotoData.plantIds ? 'bg-red-200' : ''"
                >{{ plantPhotoData.plantIds ? getHousePlantNames(plantPhotoData.plantIds) : "No Plant Chosen Yet" }}</span>
              </p>

            </div>
            
        </div>
      </v-expansion-panel-text>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn
      :disabled="!canUploadPhotos"
      @click="onUploadButtonClicked"
    >Upload Plant Photos</v-btn>

  </div>
</template>

<script lang="ts">
import SectionHeader from "@/components/SectionHeader.vue";
import { AllHousePlants, getHousePlantNames, type IHousePlant } from "@/models/IHousePlant";
import type { IPlantPhotoData } from "@/models/IPlantPhotoData";
import type { IResult } from "@/models/IResult";
import { addHousePlantPhotos, getAllPhotosForHousePlant, getLatestHousePlantPhotos } from "@/services/DatabaseService";
import { extractDateTaken, generateFirebaseUID } from "@/utils/utils";
import { get } from "http";

export default {
  components: {
    SectionHeader,
  },
  data() {
    return {
      AllHousePlants,
      getHousePlantNames,
      /** A list of plant photos that should currently be displayed in the UI */
      plantPhotoDatasToDisplay: [] as IPlantPhotoData[],
      /** A list containing the latest photo of each house plant. */
      plantPhotoDatasLatest: [] as IPlantPhotoData[],
      /** A list of metadata about each plant photo that is to be uploaded */
      plantPhotoDatasToUpload: [] as IPlantPhotoData[],
      //todo ALSO RENAME THIS ONE
      /** The ID of the currently selected house plant to display, or null or an
       * empty string if no house plant is currently selected*/
      selectedHousePlant2Ids: [] as string[],
      /** The selected plant photo files by the user, to be uploaded */
      selectedFiles: [] as File[],
      //todo RENAME THIS ONE
      /** The ID of the selected house plant that will be applied to all
       * uploaded photos, unless otherwise specified. Will be an empty string or
       * null if no house plant is selected */
      selectedHousePlantId: null as string | null,
    };
  },
  computed: {
    /** Returns true only if there are plant photos that can be uploaded */
    canUploadPhotos(): boolean {
      const areAnyPhotosSelected: boolean = this.plantPhotoDatasToUpload != null && this.plantPhotoDatasToUpload.length > 0;
      const doAllPhotosHavePlantType: boolean = this.plantPhotoDatasToUpload.every((plantPhotoData) => plantPhotoData.plantIds != null);
      return areAnyPhotosSelected && doAllPhotosHavePlantType;
    },
    /** Returns the name of the currently selected house plant */
    selectedHousePlantName(): string | undefined {
      return getHousePlantNames(this.selectedHousePlant2Ids);
    },
  },
  watch: {
    /** Updates the list of plant photo data to upload whenever the selected
     * files for uploading are changed */
    async selectedFiles(newSelectedFiles: File[]) {
      this.plantPhotoDatasToUpload = [];

      if (this.selectedFiles.length > 0) {

        // get current selected house plant, if an
        const selectedPlant: IHousePlant | undefined = this.selectedHousePlantId
          ? AllHousePlants.find((plant) => plant.id === this.selectedHousePlantId)
          : undefined;

        // extract date taken from photo file

        // Create Plant Photo Data objects for each of the selected files
        for (let selectedFile of newSelectedFiles) {

          // Try to get the date taken from EXIF data
          const dateTaken: Date | null = await extractDateTaken(selectedFile);

          const newPlantPhotoData: IPlantPhotoData = {
            id: generateFirebaseUID(),
            date: dateTaken ? dateTaken : new Date(), // default to now, if the date taken could not be extracted
            fileName: selectedFile.name,
            plantIds: selectedPlant ? [selectedPlant.id] : undefined,
            url: URL.createObjectURL(selectedFile),
          };

          this.plantPhotoDatasToUpload.push(newPlantPhotoData);
          
        }
        
      }
    },
    /** When the selected house plant ID for uploading changes, this assigns the
     * new selected plant to ALL photo files currently selected for upload */
    selectedHousePlantId(newSelectedHousePlantId: string | null) {
      const selectedPlant: IHousePlant | undefined = AllHousePlants.find((plant) => plant.id === newSelectedHousePlantId);
      for(let plantPhotoData of this.plantPhotoDatasToUpload) {
        plantPhotoData.plantIds = selectedPlant ? [selectedPlant.id] : undefined;
      }
    },
    async selectedHousePlant2Ids(newSelectedHousePlant2Ids: string[]): Promise<void>{

      // if no plant is selected, get the latest photo for each plant
      if(newSelectedHousePlant2Ids.length === 0) {
        this.getPlantPhotos();
      }

      // if a plant was selected, get all photos of just that plant
      else {
        this.getPlantPhotos(newSelectedHousePlant2Ids[0]);
      }

    },
  },
  methods: {
    async onUploadButtonClicked(): Promise<void> {
      //todo: add loading state?
      const result: IResult<boolean> = await addHousePlantPhotos(this.plantPhotoDatasToUpload, this.selectedFiles);
      console.log('Plant Upload Results: ', result);
    },
    /** When a plant photo is clicked, fetches all photos of the plant in that photo */
    onPlantPhotoClicked(housePlantIds: string[] | undefined): void {
      if(housePlantIds === undefined) return;
      this.selectedHousePlant2Ids = housePlantIds;
    },
    /** Fetches the photos for the given plant ID, or the latest photo of each
     * plant if no idea id is specified.
     * @param housePlantIds The ID of the house plants to get all photos of.
     */
    async getPlantPhotos(housePlantId?: string): Promise<void> {
      //todo: add loading state
      if(housePlantId) {
        const result = await getAllPhotosForHousePlant(housePlantId);
        this.plantPhotoDatasToDisplay = result.value!;
      } else{
        this.plantPhotoDatasToDisplay = this.plantPhotoDatasLatest;
      }
    },
  },
  async mounted(){
    const getLatestPhotosResult: IResult<IPlantPhotoData[]> = await getLatestHousePlantPhotos(AllHousePlants.map((plant) => plant.id));
    if (getLatestPhotosResult.success) {
      this.plantPhotoDatasLatest = getLatestPhotosResult.value!;
      this.plantPhotoDatasToDisplay = this.plantPhotoDatasLatest;
    }
    //todo: show error, if nothing loaded...?
  }
};
</script>
