import exifr from "exifr";

/**
 * Returns an array of dates for a given number of days before and after today.
 *
 * @param {number} daysBeforeToday - The number of days before today.
 * @param {number} daysAfterToday - The number of days after today.
 * @return {Date[]} An array of dates.
 */
export function getDatesBeforeAndAfterToday(daysBeforeToday: number, daysAfterToday: number) {
  const startDate = new Date(Date.now() - daysBeforeToday * 24 * 60 * 60 * 1000);
  return Array.from({ length: daysBeforeToday + daysAfterToday + 1 }, (_, i) => {
    return new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
  });
};

/**
 * Checks if the given date is today.
 * @param date The date to be checked.
 * @return Returns true if the given date is today, false otherwise.
 */
export function isToday(date: Date): boolean {
  const now = new Date();
  const isToday: boolean =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();
  return isToday;
}

/**
 * Formats a date object into a string in the 'YYYYMMDD' format.
 */
export function formatDateYYYYMMDD(date: Date): string {
  return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
}

/**
 * Converts a string in the format 'YYYYMMDD' to a JS Date object.
 */
export function parseDateYYYYMMDD(dateString: string): Date {
  const year = Number.parseInt(dateString.slice(0, 4));
  const month = Number.parseInt(dateString.slice(4, 6)) - 1;
  const day = Number.parseInt(dateString.slice(6));
  return new Date(year, month, day);
}

/**
 * Calculates the number of days between the given date and today. Ignores
 * hours, minutes, seconds, and milliseconds..
 * @param date  The date to calculate the number of days until or since.
 * @return The number of days until (positive) or since (negative) the given
 * date. Returns 0 if the given date is today.
 */
export function daysUntilOrSince(date: Date): number {
  const msPerDay = 24 * 60 * 60 * 1000;
  const dateAdjusted = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const todayAdjusted = new Date(Date.now());
  todayAdjusted.setHours(0, 0, 0, 0);
  const diff = dateAdjusted.getTime() - todayAdjusted.getTime();
  return Math.round(diff / msPerDay);
}

/**
 * Generates and returns a UID similar to those generated by Firebase.
 * 20 characters, upper case letters, lower case letters, and numbers.
 */
export function generateFirebaseUID() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let uid = '';
  for (let i = 0; i < 20; i++) {
      uid += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return uid;
}

/**
 * Converts an image File to WebP format with optional resizing
 * @param file Original image file
 * @param options Configuration options
 * @param options.quality WebP quality (0-1)
 * @param options.maxWidth Maximum width in pixels
 * @param options.maxHeight Maximum height in pixels
 * @returns Promise resolving to the WebP file
 */
export async function convertToWebP(file: File, options: {
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
} = {}): Promise<File> {
  const { 
    quality = 0.8,
    maxWidth = Infinity,
    maxHeight = Infinity 
  } = options;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // Calculate new dimensions maintaining aspect ratio
      let newWidth = img.width;
      let newHeight = img.height;
      
      if (maxWidth && newWidth > maxWidth) {
        newHeight = (maxWidth / newWidth) * newHeight;
        newWidth = maxWidth;
      }
      
      if (maxHeight && newHeight > maxHeight) {
        newWidth = (maxHeight / newHeight) * newWidth;
        newHeight = maxHeight;
      }

      // Create canvas with new dimensions
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }
      
      // Enable image smoothing for better quality
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      
      // Draw resized image
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Failed to create WebP blob'));
            return;
          }
          const webpFile = new File([blob], file.name.replace(/\.[^/.]+$/, '.webp'), {
            type: 'image/webp'
          });
          resolve(webpFile);
        },
        'image/webp',
        quality
      );
    };
    
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = URL.createObjectURL(file);
  });
}

/**
 * Extracts the date taken from a photo file using EXIF data
 * @param file The photo file
 * @returns Promise resolving to the date taken, or null if not available
 */
export async function extractDateTaken(file: File): Promise<Date | null> {
  try {
    // Parse EXIF data
    const exif = await exifr.parse(file);
    
    // Check various EXIF date fields in order of preference
    const dateTaken = exif?.DateTimeOriginal || // Most cameras use this
                     exif?.CreateDate ||
                     exif?.ModifyDate ||
                     null;
    
    if (dateTaken) {
      return new Date(dateTaken);
    }
    
    return null;
  } catch (error) {
    console.warn('Failed to extract EXIF date:', error);
    return null;
  }
}