<template>

  <!-- website header -->
  <header>
    <div class="flex flex-row justify-center items-center gap-4 my-12">
      <v-icon
        v-if="includeTony"
        class=""
        icon="mdi-cat"
        size="48"
        color="orange"
      />
      <v-icon
        class=""
        icon="mdi-cat"
        size="48"
        color="green"
      />
      <h1 class="text-5xl bold">The Kat App</h1>
      <v-icon
        v-if="!includeTony"
        class=""
        icon="mdi-cat"
        size="48"
        color="orange"
      />
      <v-icon
        v-else
        class=""
        icon="mdi-tortoise"
        size="48"
        color="grey"
      />
    </div>
  </header>

  <main v-if="user?.email != 'danandreginakat@gmail.com'">
      <v-text-field
        v-model="email"
        label="Email"
        type="email"
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Password"
        type="password"
      ></v-text-field>
      <v-btn
        @click="onLoginButtonClicked"
      >Login</v-btn>
  </main>

  <main v-else class="flex flex-col w-full">

    <!-- Current User Stuff -->
    <div class="flex flex-col justify-center items-center gap-4">
      <p class="text-center italic text-xs" >Welcome, {{ user.displayName ? user.displayName : user.email }}</p>
      <v-btn
        size="x-small"
        variant="flat"
        @click="onLogoutButtonClicked"
        >Logout
      </v-btn>
    </div>

    <!-- <FirstKatComponent /> -->
    <ChoreGrid />
    <ShoppingList />
    <ToDoList />
    <!-- <p5Canvas /> -->
    <GoogleSheet />
    <PlantPhotoGallery />
    <HouseModel />

  </main>

</template>

<script lang="ts">
import ShoppingList from './components/ShoppingList.vue';
import p5Canvas from './components/p5Canvas.vue';
import ToDoList from './components/ToDoList.vue';
import State from './models/State';
import GoogleSheet from './components/GoogleSheet.vue';
import HouseModel from './components/HouseModel.vue';
import { getRedirectResult, signInWithEmailAndPassword, signOut, type Auth, type User, type UserCredential } from 'firebase/auth';
import { FirebaseService } from './services/FirebaseService';
import PlantPhotoGallery from './components/PlantPhotoGallery.vue';
import ChoreGrid from './components/ChoreGrid.vue';

export default {
  components: {
    GoogleSheet,
    HouseModel,
    ShoppingList,
    ToDoList,
    p5Canvas,
    PlantPhotoGallery,
    ChoreGrid,
  },
  data() {
    return {
      includeTony: State.includeTony,
      user: null as User | null,
      email: '',
      password: '',
    }
  },
  mounted() {

    document.title = "Kat App";

    const auth = FirebaseService.GetFirebaseAuth();
    auth.onAuthStateChanged((user) => {
      this.user = user;
    })
    // getRedirectResult(auth);
  },
  methods: {
    onLoginButtonClicked() {
      this.login();
    },
    onLogoutButtonClicked() {
      const auth: Auth = FirebaseService.GetFirebaseAuth();
      signOut(auth);
    },
    login: async function () {
      const auth = FirebaseService.GetFirebaseAuth();
      try {
        // const provider = new GoogleAuthProvider();
        // const userCredentials: UserCredential = await signInWithRedirect(auth, provider);
        const userCredentials: UserCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        this.user = userCredentials.user;
      } catch (error) {
        console.log('signin failed');
      }
    }
  }
}
</script>

<style scoped></style>
